import * as React from "react";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Link from "./link";

const renderOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_node, children) => (
      <h2 className="heading">{children}</h2>
    ),

    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri}>{children}</Link>
    ),
  },
};

const ImageWithText = ({ image, mobileImage, imageAlt, text }) => {
  if (image) {
    const artDirected = [
      {
        media: "(max-width: 63.999em)",
        image: getImage(mobileImage),
      },
    ];

    const images = withArtDirection(
      getImage(image),
      mobileImage ? artDirected : []
    );

    return (
      <figure className="image-with-text">
        <GatsbyImage image={images} alt={imageAlt ?? image.title} />
        {text && (
          <figcaption>
            <div className="container">
              <div>{renderRichText(text, renderOptions)}</div>
            </div>
          </figcaption>
        )}
      </figure>
    );
  }

  return null;
};

export default ImageWithText;
